/* HomePage.css */

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  padding: 20px;
}

.hero-title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

.hero-contact-description {
  font-size: 22px;
  color: #555;
  text-align: center;
  max-width: 500px;
  margin-bottom: 24px;
}

.contact-btn {
  padding: 10px 20px;
  background-color: #555;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  color: #fff;
}

.contact-btn:hover {
  background-color: #f9f9f9;
  border: 1px solid #555;
  color: #555;
}

.info-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 60px;
  width: 80%;
  /* flex-wrap: wrap;  */
}

.info-box {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: fit-content;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Adds margin for smaller screens */
}

.info-box h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.years, .count {
  font-size: 28px;
  font-weight: bold;
}

.description {
  font-size: 16px;
  color: #555;
  text-align: center;
}

/* Responsive Adjustments with Media Queries */

@media screen and (max-width: 768px) {
  .hero-title {
    font-size: 28px;
  }

  .hero-contact-description {
    font-size: 20px;
  }

  .contact-btn {
    font-size: 1rem;
    padding: 8px 16px;
  }

  .info-section {
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 40px;
    width: 90%;
    flex-direction: column; /* Stack info boxes vertically */
  }

  .info-box {
    width: 100%; /* Full width on smaller screens */
  }

  .info-box h2 {
    font-size: 22px;
  }

  .years, .count {
    font-size: 24px;
  }

  .description {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .hero-title {
    font-size: 24px;
  }

  .hero-contact-description {
    font-size: 18px;
  }

  .contact-btn {
    font-size: 0.9rem;
    padding: 6px 12px;
  }

  .info-box h2 {
    font-size: 20px;
  }

  .years, .count {
    font-size: 22px;
  }

  .description {
    font-size: 12px;
  }
}
