/* OurProjects.css */

.contact-header {
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
}

.app-title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
}

.content{
    justify-content: center;
    align-items: center;
    display: flex;
}

.project-content {
    height: auto;
    
    border: 1px solid #dedede;
    border-radius: 12px;
    background-color: #fff;
    width: 60%;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
}

.project-image {
    border-radius: 12px;
    width: 500px;
    max-width: 60%; 
    height: auto;
}

.description-projects {
    flex-grow: 1;
    text-align: left;
    line-height: 1.6;
    margin-bottom: 20px;
    font-size: 20px;
    color: #333;
    font-family: 'Arial', sans-serif;
    padding: 10px;
}

.tech {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Ensure tech icons wrap */
}

.tech-container {
    width: 75px;
    height: 75px;
    background-color: #fff;
    border: 1px solid #dedede;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 10px; /* Allow some spacing between icons */
}

.tech-image {
    width: 50px;
    height: 50px;
}

.project-list {
    padding-bottom: 32px;
    text-align: center;
}

/* Responsive Adjustments with Media Queries */

@media screen and (max-width: 1250px) {
    .contact-header {
        font-size: 26px;
    }
    .app-title {
        font-size: 22px;
    }
    .project-content {
        width: 70%;
    }
    .description-projects {
        font-size: 18px;
    }
}

@media screen and (max-width: 920px) {
    .contact-header {
        font-size: 24px;
    }
    .app-title {
        font-size: 20px;
    }
    .project-content {
        width: 75%;
    }
    .description-projects {
        font-size: 17px;
    }
}

@media screen and (max-width: 768px) {
    .contact-header {
        font-size: 22px;
    }
    .app-title {
        font-size: 18px;
    }
    .project-content {
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
        width: 85%;
    }
    .project-image {
        max-width: 100%;
        height: auto;
    }
    .description-projects {
        font-size: 16px;
    }
}

@media screen and (max-width: 605px) {
    .contact-header {
        font-size: 20px;
    }
    .app-title {
        font-size: 18px;
    }
    .project-content {
        width: 90%;
    }
    .description-projects {
        font-size: 15px;
    }
}

@media screen and (max-width: 520px) {
    .contact-header {
        font-size: 18px;
    }
    .app-title {
        font-size: 17px;
    }
    .description-projects {
        font-size: 14px;
    }
}

@media screen and (max-width: 435px) {
    .contact-header {
        font-size: 17px;
    }
    .app-title {
        font-size: 16px;
    }
    .description-projects {
        font-size: 13px;
    }
}

@media screen and (max-width: 375px) {
    .contact-header {
        font-size: 15px;
    }
    .description-projects {
        font-size: 12px;
    }
}

@media screen and (max-width: 330px) {
    .contact-header {
        font-size: 14px;
    }
    .app-title {
        font-size: 13px;
    }
    .description-projects {
        font-size: 11px;
    }
}
