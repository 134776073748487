/* NotFound Styles */

.notfound-container {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f4f4f4; */
    font-family: 'Arial', sans-serif;
  }
  
  .notfound-content {
    text-align: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .notfound-content h1 {
    font-size: 8rem;
    margin: 0;
    color: #ff4757;
  }
  
  .notfound-content h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .notfound-content p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 30px;
  }
  
  .home-button {
    text-decoration: none;
    padding: 10px 20px;
    background-color: #000;
    color: white;
    border-radius: 5px;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .home-button:hover {
    background-color: #222222;
  }