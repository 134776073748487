/* TeamAndAbout.css */

.team-about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  padding: 40px;
  justify-content: center;
}

.team-section {
  text-align: center;
  margin-bottom: 40px;
}

.team-title {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.team-member-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  width: 250px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.team-member-image {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.team-member-info h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.team-member-info p {
  font-size: 0.9rem;
  color: #555;
}

.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  width: 75%;
}

.about-title {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  flex-direction: column; /* For small screens */
}

.about-logo {
  width: 150px;
  height: auto;
  margin-right: 0; 
  border-radius: 12px;
  margin-bottom: 20px;
}

.about-text {
  font-size: 1rem;
  text-align: center; 
  line-height: 1.6;
  color: #333;
  padding: 10px;
}

/* Media Queries for Small Screens */

@media screen and (max-width: 768px) {
  .team-title, .about-title {
      font-size: 1.5rem; 
  }

  .team-member-section {
      flex-direction: column; 
      gap: 30px; 
  }

  .team-member {
      width: 100%; 
      max-width: 350px; 
  }

  .team-member-image {
      width: 150px;
      height: 150px;
  }

  .about-section {
      width: 100%; 
  }

  .about-content {
      width: 100%;
      padding: 10px;
  }

  .about-logo {
      width: 100px; 
  }

  .about-text {
      font-size: 0.9rem;
      padding: 5px; 
  }
}

/* Media Queries for Extra Small Screens (Mobile) */

@media screen and (max-width: 480px) {
  .team-title, .about-title {
      font-size: 1.2rem; 
  }

  .team-member {
      width: 100%;
      max-width: 300px; 
  }

  .team-member-image {
      width: 120px;
      height: 120px;
  }

  .about-logo {
      width: 80px; 
  }

  .about-text {
      font-size: 0.8rem; 
      line-height: 1.4; 
  }

  .team-member-info h3 {
      font-size: 1rem;
  }

  .team-member-info p {
      font-size: 0.8rem;
  }
}
