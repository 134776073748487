/* Nav bar Styles */
.navbar {
    display:flex;
    justify-content: space-between;
    align-items: center;
    background-color: #EDEDED;
    padding: 15px 30px;
    border-radius: 12px;
    border: 1px solid #dadada;
  }
  
  .nav-container {
    padding: 15px 15px;
  }
  
  /* Left side: Logo */
  .logo img {
    width: 50px;
    height: 50px;
    border-radius: 12px;
  }
  
  /* Center navigation links */
  .nav-links {
    display: flex;
    gap: 20px; 
  }
  
  .nav-links a {
    text-decoration: none;
    font-size: 18px;
    color: black;
    font-weight: 500;
  }
  
  .nav-links a.active {
    color: black; /* Ensure the color remains the same */
    font-weight: bold;
  }
  
  .nav-links a:hover {
    color: grey; /* Optional: a hover effect */
  }
  
  /* Right side: WhatsApp button */
  .whatsapp-btn-container {
    display: flex;
  }
  
  .whatsapp-btn {
    background-color: #25D366; /* WhatsApp green */
    color: white;
    border: 2px solid #25a853; /* Optional: border for the button */
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center; /* Center the text inside the button */
  }
  
  .whatsapp-btn:hover {
    background-color: #1ebe5b; /* Slightly darker green on hover */
  }

  @media screen and (max-width: 769px){

    .nav-links a {
      font-size: 16px;
    }
    .whatsapp-btn {
      font-size: 14px;
    }
    .navbar {
      padding: 8px 15px;
      border-radius: 8px;
    }

    .nav-container {
      padding: 8px 8px;
    }

  }
  @media screen and (max-width: 500px){

    .nav-links a {
      font-size: 14px;
    }
    .whatsapp-btn {
      font-size: 12px;
    }
    .navbar {
      padding: 4px 10px;
      border-radius: 8px;
    }

    .nav-container {
      padding: 4px 4px;
    }

  }

  @media screen and (max-width: 430px){

    .nav-links a {
      font-size: 12px;
    }
    .whatsapp-btn {
      font-size: 10px;
      padding: 5px 10px;
      border-radius: 4px;
    }
    .navbar {
      padding: 4px 8px;
      border-radius: 8px;
    }

    .nav-container {
      padding: 4px 4px;
    }

  }
  @media screen and (max-width: 360px){

    .nav-links a {
      font-size: 9px;
    }
    .whatsapp-btn {
      font-size: 10px;
      padding: 5px 5px;
      border-radius: 4px;
    }
    .navbar {
      padding: 4px 8px;
      border-radius: 8px;
    }

    .nav-container {
      padding: 4px 4px;
    }

  }